export const URLS = {
  production: {
    MS_PROVI_PAY: 'https://ms-provipay.provi.com.br',
    MS_PASSWORDLESS: 'https://ms-passwordless.provi.com.br',
    MS_INVOICE: 'https://api.provi.com.br/invoice',
    SMART_LOOK: '6c2d781557aa9ae12cf5d5acf01eff2c0e1fb7c4',
    CLARITY: 'jh6s9g75he',
    V2_URL: 'https://pay2.provi.com.br',
    IUGU_JS_SCRIPT: 'https://js.iugu.com/v2',
    FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    RECAPTCHA_V3_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_V3,
    RECAPTCHA_V2_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_V2,
    MS_INVOICE_API_KEY: process.env.NEXT_PUBLIC_MS_INVOICE_API_KEY,
    UNICO_WEBFRAME_VERSION: process.env.NEXT_PUBLIC_UNICO_WEBFRAME_VERSION,
    APP_SECRET_PROOF_FACEBOOK: process.env.NEXT_PUBLIC_APP_SECRET_PROOF_FACEBOOK,
  },
  staging: {
    MS_PROVI_PAY: 'https://ms-provipay-staging.provi.com.br',
    MS_PASSWORDLESS: 'https://ms-passwordless-staging.provi.com.br',
    MS_INVOICE: 'https://ms-invoice-staging.provi.com.br',
    SMART_LOOK: '6c2d781557aa9ae12cf5d5acf01eff2c0e1fb7c4',
    CLARITY: 'jh6s9g75he',
    V2_URL: 'https://pay2-staging.provi.com.br',
    IUGU_JS_SCRIPT: 'https://js.iugu.com/v2',
    FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    RECAPTCHA_V3_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_V3,
    RECAPTCHA_V2_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_V2,
    MS_INVOICE_API_KEY: process.env.NEXT_PUBLIC_MS_INVOICE_API_KEY,
    UNICO_WEBFRAME_VERSION: process.env.NEXT_PUBLIC_UNICO_WEBFRAME_VERSION,
    APP_SECRET_PROOF_FACEBOOK: process.env.NEXT_PUBLIC_APP_SECRET_PROOF_FACEBOOK,
  },
  development: {
    MS_PROVI_PAY: 'https://ms-provipay-staging.provi.com.br',
    MS_PASSWORDLESS: 'https://ms-passwordless-staging.provi.com.br',
    MS_INVOICE: 'https://ms-invoice-staging.provi.com.br',
    SMART_LOOK: '6c2d781557aa9ae12cf5d5acf01eff2c0e1fb7c4',
    CLARITY: 'jh6s9g75he',
    V2_URL: 'https://pay2-staging.provi.com.br',
    IUGU_JS_SCRIPT: 'https://js.iugu.com/v2',
    FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    RECAPTCHA_V3_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_V3,
    RECAPTCHA_V2_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_V2,
    MS_INVOICE_API_KEY: process.env.NEXT_PUBLIC_MS_INVOICE_API_KEY,
    UNICO_WEBFRAME_VERSION: process.env.NEXT_PUBLIC_UNICO_WEBFRAME_VERSION,
    APP_SECRET_PROOF_FACEBOOK: process.env.NEXT_PUBLIC_APP_SECRET_PROOF_FACEBOOK,
  },
}

export const setEnvironment = (environment: string): typeof URLS.production => {
  return environment ? URLS[environment] : URLS.staging
}

export const ROOT_URL = setEnvironment(process.env.NEXT_PUBLIC_API_ENV)
